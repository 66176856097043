import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useAppSelector } from '../../_helpers';
import { dashboardConstants } from '../../constants';
import Button from '../coachComponents/buttons';
import Image from '../imageDisplay';
import { getServiceDetails } from '../products/slices';
import ServiceBody from '../serviceBody';
import { serviceApprove, serviceReject } from './slices';

const ServiceView = () => {
  const [rejectToggle, setRejectToggle] = useState(false);
  const [toggle, setToggle] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showToggle, setShowToggle] = useState(true);
  const dispatch = useDispatch<any>();
  const { serviceDetails, isService } = useAppSelector(
    (state) => state.products
  );
  const { isReject, isApprove } = useAppSelector((state) => state.service);
  useEffect(() => {
    dispatch(
      getServiceDetails({
        id: Number(searchParams?.get('id')),
        serviceType: searchParams?.get('serviceType') as string,
      })
    );
  }, []);
  const handleApprove = async () => {
    try {
      await dispatch(
        serviceApprove({
          id: Number(searchParams?.get('id')),
          params: {
            status: 'APPROVED',
            productType: searchParams?.get('serviceType') as string,
          },
        })
      ).unwrap();
    } catch (err) {
      return err;
    }
  };

  const RejectPopup = () => {
    const [value, setValue] = useState('');

    const handleChangeInput = (v: any) => {
      setValue((val) => (val = v));
    };
    const handleSubmit = async () => {
      value ? setToggle(0) : setToggle(1);
      value && setRejectToggle(!rejectToggle);
      try {
        value &&
          (await dispatch(
            serviceReject({
              id: Number(searchParams?.get('id')),
              params: {
                status: 'REJECTED',
                productType: searchParams?.get('serviceType') as string,
                reason: value,
              },
            })
          ).unwrap(),
          navigate(`/kun/products`));
      } catch (err) {
        return err;
      }
    };

    return (
      <>
        <div
          className={`fixed inset-0 bg-borderBlack bg-opacity-75 transition-opacity z-20 `}
        ></div>
        <div className="z-30  bg-white fixed top-[30vh] left-[40vw] p-11 rounded-xl flex flex-col w-[600px] h-[380px]   shadow-[0_-1px_10px_0px_rgba(47,74,110,0.1)]">
          <div className="flex justify-end">
            <img
              onClick={() => (setRejectToggle(!rejectToggle), setToggle(0))}
              className="cursor-pointer"
              src="/assets/closeButton.svg"
              alt=""
            />
          </div>
          <div className="flex items-start">
            <h1 className="font-satoshi rtl:font-almarai text-28 font-semibold py-7">
              {t(`COACH_PROFILE.ENTER_REASON`)}
            </h1>
          </div>

          <textarea
            value={value}
            className="outline-0 p-3 font-general rtl:font-almarai border border-borderBlack pb-7 h-[200px] rounded"
            onChange={(event) => handleChangeInput(event?.target.value)}
          />
          {toggle ? (
            <div className="text-red rtl:font-almarai">
              {t(`COACH_PROFILE.ENTER_REASON`)}
            </div>
          ) : (
            ''
          )}
          <div className="flex justify-center pt-7">
            <button
              onClick={() => {
                handleSubmit();
              }}
              className="bg-primary text-white font-satoshi rtl:font-almarai text-16  w-40 py-3 rounded flex justify-center"
            >
              {t('SUBMIT')}
            </button>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className=" flex flex-col w-full  pb-10 p-10">
      <div className="pb-6">
        <img
          className=" cursor-pointer rtl:rotate-180"
          onClick={() => history.back()}
          src="/assets/coach/arrow.svg"
          alt=""
        />
      </div>
      <div className="flex flex-row w-full justify-between  gap-5">
        <div className="w-[70%] flex flex-col shadow-lg rounded-lg">
          <div
            className={` h-[410px] w-full bg-borderBlack rounded-lg  flex justify-center items-center ${
              isService && ' animate-pulse bg-[#f3f4f6]'
            }`}
          >
            {' '}
            {!isService && (
              <Image
                id={
                  serviceDetails.serviceType === 'PACKAGE'
                    ? serviceDetails?.imageId
                    : serviceDetails?.imgId
                }
                bgImgUrl={'/assets/default-img-icon.svg'}
                bgClass={''}
                propClass={' w-full h-[410px] rounded-lg   object-fill  '}
              />
            )}
          </div>
          <div>
            <div className="flex flex-row items-center gap-4 border-b-[2px] border-borderBlack px-6 py-6">
              <div
                className={`w-[60px] h-[60px] bg-borderBlack   rounded-[30px] ${
                  isService && ' animate-pulse bg-[#f3f4f6]'
                }`}
              >
                {!isService && (
                  <Image
                    id={
                      serviceDetails.serviceType === 'PACKAGE'
                        ? serviceDetails?.imageId
                        : serviceDetails?.imgId
                    }
                    bgImgUrl={'/assets/default-img-icon.svg'}
                    bgClass={''}
                    propClass={'h-[60px] w-[60px] rounded-full  object-fill '}
                  />
                )}
              </div>
              <div>
                <div className="text-28 font-satoshi rtl:font-almarai font-bold capitalize">
                  {serviceDetails?.title}
                </div>
                <div className="text-13 font-semibold font-general rtl:font-almarai">
                  {' '}
                  <span className="text-gray font-normal">by</span>{' '}
                  <span
                    onClick={() =>
                      navigate(
                        `/kun/${dashboardConstants.SERVICE_PROFILE_VIEW}?coachId=${serviceDetails?.createdBy}`
                      )
                    }
                    className=" cursor-pointer"
                  >
                    {serviceDetails?.coachName}
                  </span>
                  <span className="text-gray font-normal">
                    - Session {serviceDetails?.subTitle}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col ">
              <div className="border-b-[2px] border-borderBlack  ">
                <div className="py-4 w-fit mb-[-1px] mx-6 border-b-[4px] border-primary text-17 font-semibold font-general rtl:font-almarai ">
                  {t(`PRODUCTS_COMPONENT.ABOUT`)}
                </div>{' '}
              </div>
              <div className="px-6">
                <div
                  dangerouslySetInnerHTML={{
                    __html: serviceDetails?.description,
                  }}
                  className="pt-6 text-16 font-general rtl:font-almarai text-gray"
                ></div>
                {/* <div
                  onClick={() => setShowToggle(!showToggle)}
                  className="flex flex-row gap-2 cursor-pointer"
                >
                  <div className="font-semibold text-16 font-general rtl:font-almarai text-primary items-center">
                    {showToggle ? t('SHOWMORE') : t('SHOWLESS')}
                  </div>
                  <img
                    className={`${!showToggle && ' rotate-180'}`}
                    src="/assets/products/downArrow.svg"
                    alt=""
                  />
                </div> */}
              </div>
              <div className="py-10 px-6 gap-4 flex flex-col">
                <ServiceBody
                  title={t(`PRODUCTS_COMPONENT.WHAT_WILL_CLIENTS`)}
                  body={serviceDetails?.intendedClientResponse?.whatWillLearn}
                  length={3}
                />
                <ServiceBody
                  title={t(`PRODUCTS_COMPONENT.WHAT_ARE_THE_REQUIREMENTS`)}
                  body={serviceDetails?.intendedClientResponse?.requirements}
                  length={3}
                />
                <ServiceBody
                  title={t(`PRODUCTS_COMPONENT.BENEFITS`)}
                  body={serviceDetails?.intendedClientResponse?.benefits}
                  length={3}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-grow">
          <div className="flex  flex-col shadow-lg px-6 pt-8 w-full pb-20">
            <div className="flex flex-row justify-between">
              <div className="text-13 text-gray font-general rtl:font-almarai">
                {t(`PRODUCTS_COMPONENT.PRICE`)}
              </div>
              <div
                className={` border border-primary text-12 text-primary font-general rtl:font-almarai px-1 rounded ${
                  serviceDetails?.requestStatus ? '' : 'hidden'
                }`}
              >
                {t(`PRODUCTS_COMPONENT.${serviceDetails?.requestStatus}`)}
              </div>
            </div>
            <div className="text-45 font-medium font-satoshi rtl:font-almarai pt-[6px] pb-8 border-b border-borderBlack">
              {t('SAR')} {serviceDetails?.price}
            </div>
            <div className="pt-8 font-bold text-20 font-satoshi rtl:font-almarai pb-6">
              {t(`PRODUCTS_COMPONENT.QUICK_INFO`)}
            </div>
            <div className="flex flex-col gap-6 pb-6 border-b border-borderBlack">
              <div className="flex flex-row gap-5 text-16 font-general rtl:font-almarai font-normal">
                <img src="/assets/products/gender.svg" alt="" />{' '}
                <div className=" capitalize">
                  {serviceDetails?.intendedClientResponse?.gender}
                </div>
              </div>
              <div className="flex flex-row gap-5 text-16 font-general rtl:font-almarai font-normal">
                <img src="/assets/products/language.svg" alt="" />{' '}
                <div>{serviceDetails?.language}</div>
              </div>
              {searchParams.get('serviceType') === 'SESSION' && (
                <div className="flex flex-row gap-5 text-16 font-general rtl:font-almarai font-normal items-start">
                  <img src="/assets/products/duration.svg" alt="" />{' '}
                  <div>
                    {searchParams.get('serviceType') === 'SESSION' &&
                      serviceDetails?.duration}
                    &nbsp;{t('MINS')}
                  </div>
                </div>
              )}
              <div className="flex flex-row gap-5 text-16 font-general rtl:font-almarai font-normal">
                <img src="/assets/products/view.svg" alt="" />{' '}
                <div>Access on desktop & mobile</div>
              </div>
            </div>
            <div className="pt-8 font-bold font-satoshi rtl:font-almarai text-20 pb-6">
              {t(`PRODUCTS_COMPONENT.FOR_WHOM`)}
            </div>
            <ul className="flex flex-col gap-4 pb-8 border-b border-borderBlack">
              {
                (serviceDetails?.intendedClientResponse?.forWhom,
                length ? (
                  serviceDetails?.intendedClientResponse?.forWhom.map(
                    (value: string, index: number) => (
                      <li
                        key={value + index}
                        className="flex flex-row gap-5 text-16 font-general rtl:font-almarai"
                      >
                        <img src="/assets/products/rightArrow.svg" alt="" />{' '}
                        <div>{value}</div>
                      </li>
                    )
                  )
                ) : (
                  <div className="text-gray font-general rtl:font-almarai">
                    --{t('NA')}--
                  </div>
                ))
              }
            </ul>

            <div className="pt-8">
              <div className="font-bold text-20 font-satoshi rtl:font-almarai pb-6">
                {t(`PRODUCTS_COMPONENT.TAGS`)}
              </div>
              <ul className="flex gap-5 flex-wrap text-16 font-normal font-general rtl:font-almarai">
                {serviceDetails?.tags &&
                  serviceDetails?.tags.map((value: string, index: number) => (
                    <li
                      key={value + index}
                      className="border border-borderBlack px-4 py-1 w-fit rounded"
                    >
                      {value}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div
            className={`flex flex-col gap-5 pt-10 ${
              ['APPROVED', 'REJECTED'].includes(serviceDetails?.requestStatus)
                ? 'hidden'
                : ''
            }`}
          >
            <div className="relative">
              <Button className={'w-full'}>
                <div
                  onClick={() => handleApprove()}
                  className="bg-primary w-full flex justify-center rounded py-4 text-white font-bold text-18 text-satoshi rtl:font-almarai px-5  gap-5 h-14"
                >
                  {isApprove ? (
                    <ClipLoader
                      color={'white'}
                      loading={true}
                      size={25}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    ''
                  )}
                  <div>{t(`PRODUCTS_COMPONENT.APPROVE`)}</div>
                </div>
              </Button>
            </div>

            <div className="relative">
              <Button className={'w-full'}>
                <div
                  onClick={() => setRejectToggle(true)}
                  className="bg-red w-full flex justify-center gap-5 rounded py-4 text-white font-bold text-18 text-satoshi rtl:font-almarai h-14"
                >
                  {isReject ? (
                    <ClipLoader
                      color={'white'}
                      loading={true}
                      size={25}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    ''
                  )}
                  <div>{t(`PRODUCTS_COMPONENT.REJECT`)}</div>
                </div>
              </Button>
            </div>

            {rejectToggle && <RejectPopup />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceView;
